.pagination-number {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.custom-btns {
  display: inline-block;
  border: none;
  margin-right: 5px;
}

.speed-btn{
  padding-right: 4px;
  padding-left:4px;
  border-radius: 5px;
}
.speed-btn:last-child{
  margin-right: unset;
}

.profile-data .card {
  /* border-top-left-radius: 40px; */
}
.profile-data .top-section {
  padding: 1.5rem;
  margin-bottom: 40px;
}
.profile-data .profile-image {
  max-width: 100px;
  margin-bottom: -65px;
}

.evaluation-page .active.bg-c-blue {
  background-color: #031f38 !important ;
}
.evaluation-page .active.bg-o-peel {
  background-color: #034265 !important;
}
.evaluation-page .active.bg-b-bell {
  background-color: #086d81 !important;
}
.evaluation-page .active.bg-c-green {
  background-color: #10977a !important;
}
.evaluation-page .active.bg-c-green2 {
  background-color: #11bb54 !important;
}

.evaluation-filters .MuiInputBase-input {
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #ced4da;
  color: #3e4347;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
}
.evaluation-filters .MuiInputBase-input::placeholder {
  color: #3e4347;
  opacity: 0.8 !important;
}
.evaluation-filters .MuiInput-underline:before {
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  content: unset !important;
  position: unset !important;
  transition: unset !important;
  border-bottom: unset !important;
  pointer-events: none !important;
}

.evaluation-filters .MuiInput-underline:after {
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  content: unset !important;
  position: unset !important;
  transform: unset !important;
  transition: unset !important;
  border-bottom: unset !important;
  pointer-events: unset;
}

.evaluation-page .progress {
  max-width: 100px;
  /* margin: auto; */
}

.evaluation-stats i{
  color: var(--primary);
}

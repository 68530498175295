.stepper-number {
  background-color: #ced4da;
  width: 30px;
  height: 30px;
  padding: 4px;
  position: relative;
  border-radius: 50%;
  color: #ffffff;
  margin: auto auto 5px;
}

.stepper-left,
.stepper-right {
position:absolute;
top:10px;
  height:2px;
  background-color: #ced4da;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
/* border-top:1px solid #BFC2C6; */
}
.stepper-right {
right:0;
left:50%;
margin-left:20px;
}
.stepper-left {
left:0;
right:50%;
margin-right:20px;
}

.stepper-left.stepper-active, .stepper-right.stepper-active, .stepper-active {
  background-color: #00ba52;
}
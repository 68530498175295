.position-col {
  border-right: 2px solid black;
  /* margin-bottom: 1rem; */
}

.position {
  /* font-size: 1.2rem; */
  color: #3d4465;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.position:hover {
  color: #00ba52;
}

.position-heading {
  margin-bottom: 2rem;
}

.position-active {
  color: #00ba52;
}

.member-picture{
  max-width: 91px;
}

/* .card-body {
  padding: 0 !important
} */
.badge {
  padding: 0.25em 0.4em !important;
}
.candidate-page .custom-col {
  padding-left: unset !important;
  padding-right: unset !important;
}
.candidate-page .content-body .container-fluid {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.candidate-page table.dataTable thead th {
  padding: 20px 10px !important;
}
/* .candidate-page table.dataTable tbody td {
  padding: 12px 10px !important;
  font-size: 14px !important;
} */
/* .candidate-page .btn {
  font-size: 0.875rem !important;
} */
.candidate-page .custom-switch-lg .custom-control-label {
  line-height: 24px !important;
}
.candidate-page .custom-control-label {
  cursor: pointer !important;
}
.candidate-page .custom-table td,
.custom-table th {
  padding: 8px 6px !important;
}

.candidate-page .color-input {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}

.candidate-page .new-tag-members {
  max-height: 150px;
  overflow-y: scroll;
}

.candidate-page .new-tag-members:active {
  box-shadow: none;
  border: none;
}

.candidate-page .new-tag-members::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Candidate Filters */
.candidate-filters .MuiInputBase-input {
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #ced4da;
  color: #3e4347;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
}
.candidate-filters .MuiInputBase-input::placeholder {
  color: #3e4347;
  opacity: 0.8 !important;
}
.candidate-filters .MuiInput-underline:before {
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  content: unset !important;
  position: unset !important;
  transition: unset !important;
  border-bottom: unset !important;
  pointer-events: none !important;
}

.candidate-filters .MuiInput-underline:after {
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  content: unset !important;
  position: unset !important;
  transform: unset !important;
  transition: unset !important;
  border-bottom: unset !important;
  pointer-events: unset;
}

.candidate-filters select {
  text-transform: capitalize !important;
}

.candidate-page .progress {
  max-width: 100px;
  /* margin: auto; */
}

.subscription-page .card {
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;


    border-top: 5px solid black;
}

.subscription-page .active-plan {
    background-color: #001c35;
    color: white;
}

.subscription-page .active-plan h1,
.subscription-page .active-plan h4{
    color: #00ba52;
}

.subscription-page .text-gray{
    color: #979797;
}

.subscription-page .features-list li{
    margin-bottom: 0.5rem;
}
.subscription-page .features-list li{
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
}

.subscription-page .plan-btn {
    font-size: 1rem !important;
}


.range-picker-input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  padding: 0.38rem 0.75rem;
  font-size: 0.8125rem;
  color: #3e4347;
}
.picker-input,
.picker-input__text {
    border-radius: 0.25rem;
}
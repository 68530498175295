.dashboard .color-c-blue {
    color: #324561;
}

.dashboard .color-o-peel {
    color: #425e8c;
}

.dashboard .color-b-bell {
    color: #5097a9;
}

.dashboard .color-c-green {
    color: #5bb49b;
}

.dashboard .stacked.card {
    cursor: pointer;
}

.dashboard .bg-c-blue {
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgb(0, 28, 53) 0%, rgb(51, 71, 98) 100%);
}

.dashboard .bg-o-peel {
    background-color: #f79e02;
    background: linear-gradient(90deg, rgb(0, 65, 100) 0%, rgb(68, 94, 142) 100%);
}

.dashboard .bg-b-bell {
    background-color: #9490e4;
    background: linear-gradient(90deg, rgb(0, 107, 126) 0%, rgb(82, 153, 171) 100%);
}

.dashboard .bg-c-green {
    background-color: #14d5af;
    background: linear-gradient(90deg, rgb(0, 148, 119) 0%, rgb(93, 181, 156) 100%);
}

.dashboard .bg-c-green2 {
    background-color: #14d5af;
    background: linear-gradient(90deg, rgb(0, 186, 82) 0%, rgb(108, 205, 128) 100%);
}

.stat .stat-icon {
    width: 50px;
    height: 50px;
    border-radius: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat .stat-icon i {
    font-size: 1.5em;
}

.stat p {
    font-size: 0.8rem;
}

/* .stat .stat-icon i:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 54px;
  background-color: hsla(0, 0%, 100%, 0.1);
  left: 16px;
  -webkit-transform: rotate(32deg);
  transform: rotate(32deg);
  top: -5px;
  transition: all 0.4s;
} */

.header-profile-user {
    width: 2.5rem;
    height: 2.5rem;
}

.dashboard .card.h-unset {
    height: unset !important;
}

.dashboard .pending-evaluations table {
    border-spacing: 0 5px;
    border-collapse: separate;
}

/*
.dashboard .pending-evaluations table tbody td {
  border-top: 1px solid ;
  border-bottom: 1px solid ;
  border-color: var(--secondary) !important;
}
.dashboard .pending-evaluations table tbody td:nth-child(1),
.dashboard .pending-evaluations table tbody td:nth-child(2){
  background-color: var(--secondary);
}

.dashboard .pending-evaluations table tbody td:last-child {
  border-right: 1px solid;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.dashboard .pending-evaluations table tbody td:first-child {
  border-left: 1px solid;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
} */

.dasbhoard .badge-soft-success {
    padding: 4px 7px;
    border-radius: 10px;
}

.badge-soft-success {
    color: #00ba52;
    background-color: rgba(0, 186, 82, 0.18);
}

.badge-soft-secondary {
    color: #001c35;
    background-color: rgba(0, 28, 53, 0.18);
}

.card.card-bx,
.card-info,
.card-bx > img {
    border-radius: 1.25rem !important;
}

/* Headings */

/* .card-title {
  font-weight: 600 !important;
} */

.cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card-profile {
    margin: 15px auto;
    width: 150px;
    height: 300px;
    /*box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.25), -1px -1px 2px 2px rgba(0, 0, 0, 0.22);*/
    cursor: pointer;
    transition: 0.4s;
}

.card-profile .card_image {
    width: inherit;
    height: inherit;
}

.card-profile .card_image img {
    width: inherit;
    height: inherit;
    object-fit: cover;
}

.card-profile .card_title {
    text-align: left;
    padding-left: 10px;
    border-radius: 0px 0px 40px 40px;
    font-size: 12px;
    margin-top: -100px;
    height: 40px;
}

.card-profile:hover {
    transform: scale(1.05);
    /*box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25), -5px -5px 30px 15px rgba(0, 0, 0, 0.22);*/
}

.title-white-profile {
    color: white;
}

.title-black {
    color: black;
}

.card_title p {
    text-shadow: 0px 0px 20px #070707;
}

.card_title img {
    object-fit: cover;
}

.position-name {
    background-color: rgba(0, 0, 0, 0.15);
}

@media all and (max-width: 500px) {
    .card-list {
        flex-direction: column;
    }
}

.member-picture {
    width: 91px;
}

.card_image .react-thumbnail-generator img {
    width: 150px;
    height: 299px;
}


/* Pending Evaluations */

.pending-evaluations .profile-badge {
    background-color: var(--primary);
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 5px;
}

.calendar__day--range {
    backgound: #dfffed
}

.calendar__day--start, .calendar__day--end, .calendar__day--selected {
    background: #4cd087;
    color: #fff;
}
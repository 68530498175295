.positions .position-chars {
  width: 42px;
  height: 42px;
  margin: auto;
  font-size: large;
}

.bg-col-red-light {
  background-color: #f7d5d7 !important;
  color: #ff4343;
}

.bg-col-green-light {
  background-color: #d8f2eb !important;
  color: #00ba52;
}
.position-media-img img{
  object-fit: contain;
}

.MuiPickersToolbar-toolbar,.MuiPickersDay-daySelected {
  background-color: var(--primary) !important;
}
.MuiButton-textPrimary {
  color: var(--primary) !important;
}
.custom-scroll::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);        /* color of the tracking area */
}
.custom-scroll-bg::-webkit-scrollbar-track {
  background: #F9F9F9 !important;        /* color of the tracking area */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #00ba52;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: #fff;  /* creates padding around scroll thumb */
}
.custom-scroll {
  overflow-x: hidden
}
.media-list .active {
  background-color: #00ba52;
  border-color: transparent;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}
.position-media-box {
  cursor: pointer;
}

.dropzone {
  min-height: 230px;
  border: 2px dashed #ced4da;
  background: #fff;
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 24px;
  width: 100%;
}
.dz-message {
  text-align: center;
  padding: 10px;
}
*, :after, :before {
  box-sizing: border-box;
}
.display-4 {
  font-size: 3.5rem;
}
.display-3, .display-4 {
  font-weight: 300;
  line-height: 1.2;
}
.media-box {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
}

.media-box img {
  object-fit: contain;
}
.media-delete {
  padding: 10px;
  color: #ff4343;
}

.media-delete:hover {
  background-color: #ff4343 ;
  cursor: pointer;
  color: #fff;
}



